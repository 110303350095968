import React, { useEffect, useState } from "react";
import css from "./style.module.scss";
import { useIntersection, useDebounce } from "react-use";

import { NavBar } from "../../../components/NavBar/NavBar";
import { Footer } from "../../../components/Footer/Footer";
import classNames from "classnames";
import Image from "next/image";
import { ContactForm } from "../../../components/lernraum/ContactForm/ContactForm";
import { Modal } from "../../../components/common/Modal/Modal";
import { useSubmitForm } from "../../../hooks/useSubmitForm";
import { Themenwelt } from "../../../components/lernraum/Themenwelt/Themenwelt";
import dynamic from "next/dynamic";
import {
  IStreamConfig,
  StreamDisruptor,
} from "../../../components/common/StreamDisruptor/StreamDisruptor";

import { ProgramCarousel } from "../../../components/lernraum/ProgramCarousel/ProgramCarousel";
import { themenWelten } from "../../../components/lernraum/Themenwelt/themen-welten";
import { ProgramModal } from "../../../components/lernraum/modals/ProgramModal/ProgramModal";
import { Domain } from "../../../types/common";
import { ImageCarousel } from "../../../components/lernraum/ImageCarousel/ImageCarousel";

const Map = dynamic(
  () =>
    import("../../../components/lernraum/Map/Map").then(
      (module) => module.Map
    ) as any,
  {
    ssr: false,
  }
);

const Lernraum = ({
  isDev,
  streamConfig,
  pixelProgram,
}: {
  isDev: boolean;
  streamConfig: IStreamConfig;
  pixelProgram: PixelProgramSerializable[];
}) => {
  const [openInfo, setOpenInfo] = useState(false);
  const [programModal, setProgramModal] = useState<PixelProgram | null>(null);

  const [tourStarted, setTourStarted] = useState(false);
  const [carouselImages, setCarouselImages] = useState<string[]>([]);

  const [panorama, setPanorama] = useState<{
    panorama: { playVideo: VoidFunction };
    viewer: {
      toggleControlBar: VoidFunction;
      toggleVideoPlay: VoidFunction;
      updateVideoPlayButton: (videoStarts: boolean) => void;
    };
  }>();
  const [panoramaPlaying, setPanoramaPlaying] = useState(false);
  const [panoramaLoading, setPanoramaLoading] = useState(false);

  const startTour = () => {
    if (typeof window !== undefined) {
      import("../../../utils/lens").then(({ startLens }) => {
        if (!document.querySelector(".panolens-canvas")) {
          setTourStarted(true);
          startLens({
            onLoadingEnd: () => setPanoramaLoading(false),
            onLoadingStart: () => setPanoramaLoading(true),
          }).then((object) => {
            setPanorama(object);
          });
        }
      });
    }
  };

  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const isInView = (intersection?.intersectionRatio ?? 0) > 0;

  useDebounce(
    () => {
      if (isInView && !tourStarted) {
        startTour();
      }
    },
    200,
    [isInView, tourStarted]
  );

  const { onSubmit, submitSuccessful, submitting, hasError } = useSubmitForm();

  if (!Array.isArray(pixelProgram)) pixelProgram = [];

  const [sortedProgram, initialProgramIndex] =
    sortPixelProgramAndCalcInitialIndex(pixelProgram);

  const calendarOrganizer = process.env.NEXT_PUBLIC_PRETIX_CALENDAR_ORGANIZER;
  const eventBaseUrl = process.env.NEXT_PUBLIC_PRETIX_BASE_URL;

  useEffect(() => {
    const fetchImages = async () => {
      const response = await fetch("/api/pixelwerkstatt-images");
      const data = await response.json();
      setCarouselImages(data.images.sort(() => Math.random() - 0.5));
    };

    fetchImages();
  }, []);

  return (
    <div>
      <Modal
        className={css["modal"]}
        show={openInfo}
        title="Angebote der PIXELWERKSTATT"
        onClose={() => setOpenInfo(false)}
      >
        In der PIXELWERKSTATT stärken wir Kita-Pädagog:innen in Praxis wie auch
        Ausbildung im kreativen, kritischen und sicheren Umgang mit digitalen
        Medien. Wir bieten Kita-Teams Inspirationen sowie methodische,
        technische und rechtliche Unterstützung. Zu allen Themenfeldern
        digitaler Bildungsarbeit mit Kindern stehen Fachkräften vielfältige
        digitale Technologien und Anwendungen zum Kennenlernen und Ausprobieren
        zur Verfügung und laden zum Forschen und Experimentieren ein. <br />
        Neben individuell zugeschnittenen Teamtagen organisieren wir Workshops,
        Vorträge, Tagungen und weitere Bildungsangebote.
      </Modal>

      <ProgramModal
        program={programModal}
        onClose={() => setProgramModal(null)}
      />

      <NavBar isDev={isDev} domain={Domain.PIXELWERKSTATT} />

      {/* <div className={css["chat-bot-icon"]}>
  <ChatBot />
</div> */}

      <div className={css["main-layout"]}>
        <div style={{ width: "100%", height: 280, position: "relative" }}>
          <Image
            objectFit="contain"
            layout="fill"
            src={require("../../../public/images/PW_Header.svg")}
            alt=""
          />
        </div>

        <div className={css["first-text"]}>
          <div>
            Du möchtest digitale Medien pädagogisch sinnvoll und souverän im
            Kitaalltag einsetzen und die Chancen digitaler Bildung in der Kita
            entdecken? <br />
            Herzlich Willkommen in der PIXELWERKSTATT Amberg!
          </div>
          <button onClick={() => setOpenInfo(true)} aria-label="Erfahre Mehr">
            MEHR INFOS
          </button>
        </div>

        {sortedProgram.length > 0 && (
          <div className={css["event-program-panel"]}>
            <div className="d-flex flex-column flex-lg-row justify-content-between">
              <h4>Veranstaltungen</h4>
              <div>
                <img
                  tabIndex={0}
                  className={classNames(css["arrow-highlight-left"], {
                    [css.active]: true,
                  })}
                  src="/images/middle-action-bar/Meeting_Icon_Arrow.svg"
                  alt="yellow arrow"
                />
                <a
                  href={"https://link.kita.bayern/pw-website-programm"}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <button className={css["event-program-all-button"]}>
                    Programm als PDF
                  </button>
                </a>
              </div>
            </div>

            <p>
              Das Jahresprogramm der PIXELWERKSTATT richtet sich an alle
              Fachkräfte aus dem frühpädagogischen Bereich in Bayern.
            </p>

            <ProgramCarousel
              initialIndex={initialProgramIndex}
              programCards={sortedProgram}
              onCardClick={(e) => {
                setProgramModal(e);
              }}
            />
          </div>
        )}

        <div className={css["second-text"]}>
          <h4>Themenwelten</h4>
          <p>
            Die PIXELWERKSTATT bietet vor Ort in verschiedenen Themenwelten
            digitale Medien und Equipment zum Ausprobieren und Experimentieren
            an. Wir stehen dir dabei mit medienpädagogischen, methodischen und
            technischen Tipps zur Seite.
          </p>

          <div className={classNames("row", css["themenwelt-container"])}>
            {themenWelten.map((welt) => {
              return (
                <div
                  className={classNames("col-12 col-md-6 col-xl-3")}
                  key={welt.pic}
                >
                  <Themenwelt
                    welt={{
                      ...welt,
                      content: welt.content,
                    }}
                  />
                </div>
              );
            })}
            <div
              className={classNames(
                "d-none d-md-block col-12 col-md-6 col-xl-3"
              )}
            >
              <div>
                <img
                  className={css["themenwelt-arrow"]}
                  src="/images/pixelwerkstatt/themenwelten/ARROW.svg"
                  alt=""
                />
              </div>
              <div className={css["themenwelt-hinweis"]}>
                Gehe jetzt auf Entdeckungs&shy;tour!
              </div>
            </div>
          </div>
        </div>
        <div className={css["third-text"]}>
          <h4 className={classNames("mb-4")}>Impressionen</h4>

          <ImageCarousel
            imageList={carouselImages}
            initialIndex={0}
            onCardClick={() => {}}
          />
          <h5>Raumtour</h5>
          <p>
            Wenn du einen Blick in unsere Räumlichkeiten werfen möchtest, starte
            unsere Raumtour. Klicke mit der Maus ins Bild und zoome und ziehe
            das Bild in eine beliebige Richtung. Klicke auf die Symbole, um zu
            einzelnen Stationen und Angeboten weitere Hinweise zu bekommen.
          </p>
          <div ref={intersectionRef} className={css["tour-container"]}>
            <div id="container"></div>

            {/* {panoramaLoading && ( */}
            {!panoramaPlaying && (
              <div className={css["roomtour-overlay"]}>
                <div>
                  <img
                    className={css["roomtour-icon"]}
                    src="/360/noun-360.svg"
                    alt=""
                  />
                </div>
                Die PIXELWERKSTATT in 360° entdecken...
                <div>
                  <button
                    onClick={() => {
                      setPanoramaPlaying(true);

                      (window as any).panorama = panorama;

                      panorama?.viewer.toggleControlBar();
                      panorama?.viewer.toggleVideoPlay();
                      // false -> video starts
                      panorama?.viewer.updateVideoPlayButton(false);
                    }}
                  >
                    Roomtour starten
                  </button>
                </div>
              </div>
            )}
            {/* )} */}
            {/* {!tourStarted && (
              <button className={css["start-360-tour"]} onClick={startTour}>
                360° Rundgang starten
              </button>
            )} */}
          </div>
        </div>
        <div className={classNames(css["contact-panel"])}>
          <div className="row">
            <div
              className={classNames("col-md-12 col-lg-6", css["opening-times"])}
            >
              <h4>Öffnungszeiten und Termine</h4>
              <p>
                Die PIXELWERKSTATT hat feste Öffnungszeiten, bei denen du
                einfach vorbeikommen kannst.
              </p>
              <p>
                <b>Jeder letzte Dienstag im Monat von 10:00-16:00 Uhr</b>{" "}
                (Feiertage ausgenommen)
              </p>
              <p>Schließtage und Ersatztermine:</p>
              
              {/*<div>
                <table>
                  <tbody>
                    <tr>
                      <td className={css["closing-days"]}>• 25.02.2025:</td>
                      <td>Schließtag</td>
                    </tr>
                  </tbody>
                </table>
              </div>*/}

              <p>&nbsp;</p>
              <p>
                Willst du mit deinem Team einen Tag bei uns in der
                PIXELWERKSTATT verbringen? Gemeinsam experimentieren, kreativ
                sein und neue medienpädagogische Methoden kennenlernen? Wir
                bieten auch Teamtage und weitere Fortbildungen zu bestimmten
                oder individuellen Themen an. Melde dich bei uns und nutze dafür
                das Kontaktformular.
              </p>
            </div>

            <div
              className={classNames("col-md-12 col-lg-6", css["contact-form"])}
            >
              <ContactForm
                isSubmitting={submitting}
                onSubmit={onSubmit}
                submitFailed={hasError}
                submitSuccessful={submitSuccessful}
              />
            </div>
          </div>

          {/* <div className={classNames(css["calendar-panel"])}>
            <CalendarWidget
              baseUrl={eventBaseUrl ? eventBaseUrl : ""}
              organizer={calendarOrganizer ? calendarOrganizer : ""}
            />
          </div> */}
        </div>

        <div className={classNames(css["location-panel"])}>
          <div className="row">
            <div
              className={classNames(css["where-are-we"], "col-md-12 col-lg-6")}
            >
              <h4>Adresse</h4>
              <p>
                PIXELWERKSTATT
                <br />
                Mildred-Scheel-Str. 4
                <br />
                92224 Amberg
              </p>
              <p>
                pixelwerkstatt@kita.bayern <br /> 09621 / 96 552 199 3
              </p>
              <h5>Mit dem Auto</h5>
              <p>Parkmöglichkeiten direkt vor der PIXELWERKSTATT</p>
              <h5>Mit den öffentlichen Verkehrsmöglichkeiten</h5>
              <p>Bus 403 Richtung „Demo“</p>
              <p>Haltestelle „Landesamt für Pflege“</p>
              <p>
                Abfahrt ab Busbahnhof (ZOB) Amberg <br />
                jeweils halbstündlich um _:20 Uhr und um _:50 Uhr
              </p>
            </div>
            <div className={classNames("col-md-12 col-lg-6", css["map"])}>
              <Map />

              {/* <Image
                className={css["people"]}
                objectFit="cover"
                layout="fill"
                src={require("../../../public/images/image-349.png")}
                alt="Standort der PIXELWERKSTATT. Darstellung eines Auschnittes der Karte."
              /> */}
            </div>
          </div>
        </div>
      </div>

      <div className={css["disruptor-wrapper"]}>
        {streamConfig.showDisruptor && (
          <StreamDisruptor
            title={streamConfig.title}
            subtitle={streamConfig.subtitle}
            disruptorStreamSource={streamConfig.disruptorStreamSource}
            streamStart={streamConfig.streamStart}
          />
        )}
      </div>

      <div>
        <Footer domain={Domain.PIXELWERKSTATT} />
      </div>
    </div>
  );
};

function sortPixelProgramAndCalcInitialIndex(
  pixelProgram: PixelProgramSerializable[]
): [PixelProgram[], number] {
  const currentDateAndTime = new Date();
  const currentDateWithoutTime = new Date(
    currentDateAndTime.getFullYear(),
    currentDateAndTime.getMonth(),
    currentDateAndTime.getDate()
  );
  const filterDate = new Date(
    currentDateAndTime.getFullYear(),
    currentDateAndTime.getMonth(),
    currentDateAndTime.getDate()
  );

  filterDate.setDate(currentDateWithoutTime.getDate() - 2);

  const sortedProgram: PixelProgram[] = pixelProgram
    .map((e) => {
      return {
        title: e.title,
        titleShort: e.titleShort,
        titleLong: e.titleLong,
        description: e.description,
        type: e.type,
        features: e.features,
        liveStreamTime: e.liveStreamTime,
        price: e.price,
        referee: e.referee,
        startDate: new Date(e.startDate),
        endDate: new Date(e.endDate),
        url: e.url,
        showOnLandingPage: e.showOnLandingPage,
        showApplyButton: e.showApplyButton,
      };
    })
    .filter((e) => e.startDate >= filterDate)
    .filter((e) => e.showOnLandingPage == true)
    .sort((a, b) => +a.startDate - +b.startDate);

  const initialProgramIndex = sortedProgram.findIndex(
    (e) =>
      e.startDate.getFullYear() > currentDateWithoutTime.getFullYear() ||
      (e.startDate.getFullYear() >= currentDateWithoutTime.getFullYear() &&
        e.startDate.getMonth() > currentDateWithoutTime.getMonth()) ||
      (e.startDate.getMonth() === currentDateWithoutTime.getMonth() &&
        e.startDate.getDate() >= currentDateWithoutTime.getDate())
  );

  return [sortedProgram, initialProgramIndex];
}

export default Lernraum;
